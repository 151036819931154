import React, { useState } from "react"
import {
  Wrapper,
  FormWrapper,
  InputWrapper,
  ButtonWrapper,
} from "./ModifyPassword.module.css"
import { OrangeTitle } from "./../../../../css/Titre.module.css"
import Button from "./../../../../components/Button"
import { PaddingBottom10 } from "./../../../../css/Padding.module.css"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import firebase from "gatsby-plugin-firebase"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Profil = ({ user }) => {
  const [errorSnackbarIsOpened, setErrorSnackbarIsOpened] = useState(false)
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)

  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")

  const handleErrorClose = () => {
    setErrorSnackbarIsOpened(false)
  }

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  const checkNewPasswordFormat = (newPassword, confirmNewPassword) => {
    if (
      newPassword.length < 6 ||
      confirmNewPassword.length < 6 ||
      newPassword !== confirmNewPassword
    ) {
      return false
    }
    return true
  }

  const handleValidateButton = () => {
    if (
      currentPassword.length > 0 &&
      checkNewPasswordFormat(newPassword, confirmNewPassword)
    ) {
      const newUser = {
        password: newPassword,
      }
      firebase
        .firestore()
        .collection("users")
        .doc(user.id)
        .update(newUser)
        .then(firestoreResult => {
          const user = firebase.auth().currentUser
          user
            .updatePassword(newPassword)
            .then(() => {
              setSuccessSnackbarIsOpened(true)
            })
            .catch(error => {
              //console.log("error: ", error)
              setErrorSnackbarIsOpened(true)
            })
        })
        .catch(firestoreError => {
          //console.log("error: ", firestoreError)
          setErrorSnackbarIsOpened(true)
        })
    } else {
      setErrorSnackbarIsOpened(true)
    }
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Changer de mot de passe</h1>
      <div className={FormWrapper}>
        <div className={InputWrapper}>
          <div className={PaddingBottom10}>
            <label htmlFor="currentPassword">Mot de passe actuel</label>
          </div>
          <input
            type="password"
            placeholder="Mot de passe actuel"
            onChange={e => setCurrentPassword(e.target.value)}
          />
        </div>
        <div className={InputWrapper}>
          <div className={PaddingBottom10}>
            <label htmlFor="newPassword">Nouveau mot de passe</label>
          </div>
          <input
            type="password"
            placeholder="Nouveau mot de passe (6 caractères minimum)"
            onChange={e => setNewPassword(e.target.value)}
          />
        </div>
        <div className={InputWrapper}>
          <div className={PaddingBottom10}>
            <label htmlFor="confirmNewPassword">
              Confirmation du nouveau mot de passe
            </label>
          </div>
          <input
            type="password"
            placeholder="Confirmation du nouveau mot de passe (6 caractères minimum)"
            onChange={e => setConfirmNewPassword(e.target.value)}
          />
        </div>
      </div>
      <div className={ButtonWrapper}>
        <Button alt="" onClick={() => handleValidateButton()}>
          Valider
        </Button>
      </div>
      <Snackbar
        open={errorSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Oops... Une erreur est survenue. Vérifie les informations que tu as
          rentré.
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bravo ! Tu as bien changé ton mot de passe.
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Profil
